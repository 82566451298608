<template>
  <div>
    <div v-if="$width > 767">
      <el-row class="header">
        <div style="width: 1200px; margin: 0 auto">
          <el-col :span="16">
            <div class="left">
              欢迎来到天润资本管理（北京）有限公司官方网站！
            </div>
          </el-col>
          <el-col :span="8">
            <el-row>
              <!-- <el-col :span="16" class="col">搜索 </el-col> -->
              <a href="/user" v-if="isLogin" style="color: #9ca1ab">
                <el-col :span="4" :offset="16" class="col login"
                  >用户中心</el-col
                >
              </a>
              <a href="/login" v-if="!isLogin" style="color: #9ca1ab">
                <el-col :span="4" :offset="16" class="col login">登录</el-col>
              </a>
              <a href="/regis" style="color: #9ca1ab">
                <el-col :span="4" class="col login">注册</el-col>
              </a>
            </el-row>
          </el-col>
        </div>
      </el-row>

      <el-row class="header row2">
        <el-col :span="5" style="height: 90px">
          <img
            src="/images/1572921078_0_img.png"
            style="margin-left: 0; height: 69px; margin-top: 11px"
          />
        </el-col>

        <el-col :span="19" style="height: 80px">
          <div style="display: flex; height: 90px">
            <a
              href="http://www.richyard.net/"
              class="menu"
              @mouseenter="submenushow = 1"
            >
              网站首页
            </a>
            <a
              href="http://www.richyard.net/gywm_7809"
              class="menu"
              @mouseenter="submenushow = 2"
            >
              关于我们
              <div
                class="submenu"
                v-if="submenushow == 2"
                @mouseleave="submenushow = 0"
              >
                <a href="http://www.richyard.net/gywm_7809" class="line"
                  >公司简介</a
                >
                <a href="http://www.richyard.net/dsczc" class="line"
                  >董事长致辞</a
                >
                <a href="http://www.richyard.net/zzjg" class="line">组织架构</a>
                <a href="http://www.richyard.net/zzzs" class="line">资质证书</a>
                <a href="http://www.richyard.net/gltd" class="line">管理团队</a>
                <a href="http://www.richyard.net/qywh" class="line">企业文化</a>
              </div>
            </a>
            <a
              href="http://www.richyard.net/alzs"
              class="menu"
              @mouseenter="submenushow = 3"
            >
              案例展示
            </a>
            <a
              href="http://www.richyard.net/hzhb"
              class="menu"
              @mouseenter="submenushow = 4"
            >
              合作伙伴
            </a>
            <a
              href="http://www.richyard.net/hyxw"
              class="menu"
              @mouseenter="submenushow = 5"
            >
              资讯苑
            </a>
            <a href="/its/list" class="menu" @mouseenter="submenushow = 6"
              >旗下产品</a
            >
            <a
              href="http://www.richyard.net/lxwm"
              class="menu"
              @mouseenter="submenushow = 7"
            >
              联系我们
            </a>
          </div>
        </el-col>
      </el-row>
    </div>

    <div v-if="$width < 767">
      <el-row class="header">
        <el-col :span="24">
          <el-row>
            <a href="/user" v-if="isLogin" style="color: #9ca1ab">
              <el-col
                :span="4"
                :offset="16"
                class="col login"
                style="border-left: 1px solid #efefef"
                >用户中心</el-col
              >
            </a>
            <a href="/login" v-if="!isLogin" style="color: #9ca1ab">
              <el-col
                :span="4"
                :offset="16"
                class="col login"
                style="border-left: 1px solid #efefef"
                >登录</el-col
              >
            </a>
            <a href="/regis" style="color: #9ca1ab">
              <el-col :span="4" class="col login">注册</el-col>
            </a>
          </el-row>
        </el-col>
      </el-row>

      <el-row class="header row2">
        <el-col :span="16">
          <img src="/images/1572921078_0_img.png" />
        </el-col>
        <el-col :span="8">
          <!-- <i class="el-icon-more"></i> -->
          <i class="fa fa-bars" aria-hidden="true"></i>
        </el-col>
        <!-- <el-col :span="2" :offset="4">
          <a href="/" class="menu">网站首页</a>
        </el-col>
        <el-col :span="2"><a href="/" class="menu">关于我们</a></el-col>
        <el-col :span="2"><a href="/" class="menu">案例展示</a></el-col>
        <el-col :span="2"><a href="/" class="menu">合作伙伴</a></el-col>
        <el-col :span="2"><a href="/" class="menu">资讯苑</a></el-col>
        <el-col :span="2"><a href="/its/list" class="menu">旗下产品</a></el-col>
        <el-col :span="2"><a href="/" class="menu">联系我们</a></el-col> -->
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "trheader",
  data() {
    return {
      isLogin: false,
      submenushow: 0,
    };
  },
  components: {},
  methods: {
    toUser: () => {},
  },
  created() {
    this.isLogin = localStorage.getItem("tianruntoken");
  },
};
</script>
<style scoped>
.header {
  height: 50px;
  line-height: 50px;
  color: #9ca1ab;
  border-bottom: 1px solid #efefef;
}
.login:hover {
  color: #ea5504;
}
.header .left {
  padding-left: 20px;
}
.col {
  border-left: 1px solid #efefef;
  text-align: center;
  cursor: pointer;
}
.row2 {
  height: 90px;
  line-height: 90px;
  border: none;
  margin: 0 auto;
  width: 1200px;
}
.menu {
  color: #333333;
  font-size: 16px;
  text-align: center;
  flex: 1;
  transition: 0ms;
}
.menu:hover {
  color: #ea5504;
  border-bottom: 4px solid #ff9d43;
}
.submenu {
  background-color: white;
}
.submenu .line {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  display: block;
}
.line:hover {
  color: #ea5504;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .header {
    height: 40px;
    line-height: 40px;
  }
  .row2 {
    height: 50px;
  }
  .row2 img {
    margin-left: 10px;
    height: 50px;
  }
}
</style>
