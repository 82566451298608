<template>
  <div class="home">
    <div>
      <img src="/images/banner.jpg" style="width: 100%; margin-bottom: 30px" />
    </div>
    <div v-if="width > 767">
      <el-row class="th">
        <el-col :span="7">基金名称</el-col>
        <el-col :span="5">基金编号</el-col>
        <el-col :span="4">基金类型</el-col>
        <el-col :span="4">管理类型</el-col>
        <el-col :span="4">基金详情</el-col>
      </el-row>
      <el-row
        class="tr"
        :class="idx % 2 == 1 ? 'tr2' : ''"
        v-for="(gm, idx) of gmlist.slice((current - 1) * 10, current * 10)"
        :key="'gm' + idx"
      >
        <el-col :span="7">{{ gm.pName }}</el-col>
        <el-col :span="5">{{ gm.jjbh }}</el-col>
        <el-col :span="4">{{ gm.jjlx }}</el-col>
        <el-col :span="4">{{ gm.gllx }}</el-col>
        <el-col :span="4">
          <a class="btn" @click="toDetail(gm)">点击查看</a>
        </el-col>
      </el-row>
    </div>

    <div v-if="width < 767">
      <el-row class="th">
        <el-col :span="16">基金名称</el-col>
        <el-col :span="8">基金详情</el-col>
      </el-row>

      <el-row
        class="tr"
        v-for="(gm, idx) of gmlist.slice((current - 1) * 10, current * 10)"
        :key="'gm' + idx"
      >
        <el-col :span="17">{{ gm.pName }}</el-col>
        <el-col :span="7">
          <a class="btn" @click="toDetail(gm)">点击查看</a>
        </el-col>
      </el-row>
    </div>
    <div style="text-align: center; padding: 30px">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="gmlist.length"
        :current-page="current"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "Home",
  data() {
    return {
      width: 1000,
      gmlist: [],
      current: 1,
    };
  },
  components: {},
  methods: {
    getGmlist() {
      api.gmList().then((res) => {
        if (res.code == 200 && res.msg == "查询成功") {
          this.gmlist.splice(0, this.gmlist.length);
          this.gmlist = this.gmlist.concat(res.rows);
        }
      });
    },
    toDetail(gm) {
      sessionStorage.setItem("gmdetail", JSON.stringify(gm));
      location.href = "/its/detail/" + gm.pid;
    },
    changePage(e) {
      this.current = e;
    },
  },
  mounted() {
    this.width = screen.width;
    this.getGmlist();
  },
};
</script>
<style scoped>
.home {
}
.th {
  width: 98%;
  margin: 0 1%;
  height: 60px;
  line-height: 60px;
  background: #f4f4f4;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.tr {
  width: 98%;
  margin: 0 1%;
  height: 50px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid #f4f4f4;
  color: #666666;
}
.tr2 {
  background: #fafafa;
}
.tr:hover {
  background: #ec6526;
  color: white;
}
.tr .btn {
  background: #ec6526;
  color: #fff;
  padding: 5px 10px;
}
.tr:hover .btn {
  background: #fff;
  color: #ec6526;
  padding: 5px 10px;
}
.page {
  margin: 20px auto;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .tr {
    line-height: 24px;
    padding: 10px 0;
    height: auto;
  }
}
</style>
