<template>
  <div>
    <div v-if="$width > 767">
      <el-row :gutter="20">
        <!-- <el-col :span="6">
          <img src="/images/1572921078_0_img.png" style="margin-top: 10px" />
        </el-col> -->
        <el-col :span="24" class="middle">
          <div>
            <div class="foot_ct">
              友情链接: <a href="http://www.cs.com.cn/">中国证券报</a> |
              <a href="http://www.chinacape.org/">中国股权投资基金协会</a> |
              <a href="https://www.amac.org.cn/">中国证券投资基金业协会</a> |
              <a href="http://www.csrc.gov.cn/">中国证券监督管理委员会</a> |
            </div>
            <div class="foot_ct">
              <div class="line">
                与本网站所载全部资料有关的所有版权、专利等知识产权及其他任何权利均为天润投资所有，未经许可不得使用，不得转载、转播、摘编。
              </div>
              <div class="line">
                版权所有copyright@天润资本管理（北京）有限公司
              </div>
              <div class="line">
                <a
                  href="https://beian.miit.gov.cn/#/Integrated/index"
                  target="_blank"
                >
                  京ICP备12018446号-1号
                </a>
                <a
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000001"
                  target="_blank"
                >
                  京公安备：11010202007425
                </a>
              </div>
            </div>
          </div>
        </el-col>
        <!-- <el-col :span="3" class="">
          <img src="/images/1477885248_0_img.jpeg" style="margin-top: 20px" />
        </el-col> -->
      </el-row>
    </div>

    <div v-if="$width < 767" style="margin: 20px auto">
      <div style="text-align: center">
        <img src="/images/1572921078_0_img.png" />
      </div>
      <div class="foot_ct">
        友情链接: <a href="http://www.cs.com.cn/">中国证券报</a> |
        <a href="http://www.chinacape.org/">中国股权投资基金协会</a> |
        <a href="https://www.amac.org.cn/">中国证券投资基金业协会</a> |
        <a href="http://www.csrc.gov.cn/">中国证券监督管理委员会</a> |
      </div>
      <div class="foot_ct">
        <div class="line">
          与本网站所载全部资料有关的所有版权、专利等知识产权及其他任何权利均为天润投资所有，未经许可不得使用，不得转载、转播、摘编。
        </div>
        <div class="line">版权所有copyright@天润资本管理（北京）有限公司</div>
        <div class="line">
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
          >
            京ICP备12018446号-1号
          </a>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202007425"
            target="_blank"
          >
            京公安备：11010202007425
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trfooter",
  components: {},
  methods: {},
};
</script>
<style scoped>
.foot_ct {
  font-size: 12px;
  color: #666;
  color: white;
  padding-bottom: 10px;
}
.foot_ct:nth-child(1) {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e8e8e8;
}
a {
  color: #666;
}
.middle {
  border-left: 1px solid #e8e8e8;
  text-align: center;
  background-color: rgb(69, 123, 202);
  color: white;
  line-height: 18px;
}
.middle a {
  color: white;
}
.line {
  line-height: 20px;
}
.line:nth-child(1) {
  margin-top: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .foot_ct {
    width: 94%;
    margin: 0 3%;
    line-height: 24px;
  }
}
</style>
