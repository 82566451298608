import axios from "./request";
const api = {};
//图片验证码
api.captchaImage = () => {
  return axios.request({
    url: "/captchaImage",
    method: "get",
  });
};
//注册
api.regis = (data) => {
  return axios.request({
    url: "/webSite/user/add",
    method: "post",
    data,
  });
};
//登录
api.login = (data) => {
  return axios.request({
    url: "/webSite/user/login",
    method: "post",
    data,
  });
};
//用户信息
api.getInfo = () => {
  return axios.request({
    url: "/webSite/user/getInfo",
    method: "get",
  });
};
//修改用户信息
api.edit = (data) => {
  return axios.request({
    url: "/webSite/user/edit",
    method: "post",
    data,
  });
};
//重置密码
api.updatePwd = (data) => {
  return axios.request({
    url: "/webSite/user/updatePwd",
    method: "post",
    data,
  });
};
//基金列表
api.gmList = () => {
  return axios.request({
    url: "/webSite/user/list",
    method: "get",
  });
};
//基金详情
api.gmDetail = (params) => {
  //fid=类别1月报，2季报，3，年报，4清算，5公告&pid=分类id主键
  return axios.request({
    url: "/webSite/user/listDetail",
    method: "get",
    params,
  });
};
//我的购买记录
api.myGmlist = () => {
  return axios.request({
    url: "/webSite/user/myGmlist",
    method: "get",
  });
};
//设置答题分数
api.setResultScore = (data) => {
  return axios.request({
    url: "/webSite/user/setResultScore",
    method: "post",
    data,
  });
};
//查询用户答题分数
api.getResultScore = () => {
  return axios.request({
    url: "/webSite/user/getResultScore",
    method: "post",
  });
};

// export const update = (data) => {
//   return axios.request({
//     url: "/update",
//     method: "post",
//     data,
//   });
// };
// export const getList = (params) => {
//   return axios.request({
//     url: "/getList",
//     method: "get",
//     params,
//   });
// };
export default api;
