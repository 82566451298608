export default {
  // 配置显示在浏览器标签的title
  title: "天润资本官方网站",

  cookieExpires: 1,

  // useI18n: true,

  // api请求基础路径
  baseUrl: {
    dev: "http://47.118.40.207/prod-api/",
    pro: "http://47.118.40.207/prod-api/",
  },

  // 默认打开的首页的路由name值，默认为home
  homeName: "/",

  // 需要加载的插件
  plugin: {
    "error-store": {
      // 设为false后不会在顶部显示错误日志徽标
      showInHeader: true,
      // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
      developmentOff: true,
    },
  },
};
