import axios from "axios";

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }

  //eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI4YTA5MDRlLThmNWUtNGMxMC05YTU2LTRmMDg4M2I1NzVkZCJ9.Q5PTc-R2d2B8r9C_8DloBJFb-iZitAOWg5eQsQjthWlKGGtH6lYZVb8O7QwO19oZTwhobDAJO9hHFljLDMQPvA
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {},
    };
    let token = localStorage.getItem("tianruntoken");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  }

  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }

  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        this.destroy(url);
        const { data } = res;
        if (typeof data === "object") {
          // 自己处理
        }
        return data;
      },
      (error) => {
        this.destroy(url);
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config,
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url },
          };
          console.log(errorInfo);
        }
        return Promise.reject(error);
      }
    );
  }

  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}
export default HttpRequest;
