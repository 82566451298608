<template>
  <div id="app">
    <trheader></trheader>
    <router-view />
    <trfooter></trfooter>
  </div>
</template>

<script>
import trheader from "@/components/trheader.vue";
import trfooter from "@/components/trfooter.vue";
export default {
  name: "App",
  components: {
    trheader,
    trfooter,
  },
};
</script>

<style>
html,
body,
#app {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Microsoft YaHei";
  background: #fff;
  text-align: justify;
  -webkit-text-size-adjust: none;
  font-size: 14px;
  height: auto;
}
a {
  text-decoration: none;
  color: #000;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.el-header,
.el-main {
  padding: 0 !important;
}
.el-footer {
  border-top: 1px solid #e8e8e8;
}
.el-main {
  overflow: visible;
}
</style>
