import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/its/list",
    name: "/its/list",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/its/detail/:id",
    name: "/its/detail",
    component: () => import("@/views/itsinfo.vue"),
  },
  {
    path: "/user/",
    name: "/user/",
    component: () => import("@/views/user.vue"),
  },
  {
    path: "/question",
    name: "/question/",
    component: () => import("@/views/question.vue"),
  },
  {
    path: "/regis",
    name: "/regis/",
    component: () => import("@/views/regis.vue"),
  },
  {
    path: "/login",
    name: "/login/",
    component: () => import("@/views/login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
